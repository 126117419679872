@use "@angular/material"as mat;
@import 'palette';
@import "@angular/material/theming";
@include mat.core();

$my-primary: mat.define-palette($accent-color);
$my-accent : mat.define-palette($accent-color);
$my-warn   : mat.define-palette($primary-color, A100);

// Define light theme colors
$light-theme: mat.define-light-theme((color: (primary: $my-primary, accent: $my-accent, warn: $my-warn)));

@include mat.all-component-themes($light-theme);

$my-dark-primary: mat.define-palette($primary-color, 50);

// Define dark theme colors
$dark-theme: mat.define-dark-theme((color: (primary: $my-dark-primary, accent: $my-accent, warn: $my-warn)));

.mat-app-background {
  background-color: $lightColor;
  color           : $darkColor;
}

.mat-app-background.theme-dark {
  @include mat.all-component-colors($dark-theme);
  background-color: $darkColor !important;
  color           : $lightColor;
}