.loader-main {
    width        : 60px;
    height       : 60px;
    position     : fixed;
    top          : 50%;
    left         : 50%;
    display      : none;
    border       : 3px solid #DEDEDE;
    border-radius: 50%;
    border-top   : 3px solid @themeColor;

    z-index          : 999999999999;
    -webkit-animation: spin 2s linear infinite;
    animation        : spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}