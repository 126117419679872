@use 'sass:map';

$lightColor: #fafafa;
$darkColor: #171717;
$accentColor: #ef6c00;

$primary-color:(
    50 : #e3e3e3,
    100 : #b9b9b9,
    200 : #8b8b8b,
    300 : #5d5d5d,
    400 : #3a3a3a,
    500 : #171717,
    600 : #141414,
    700 : #111111,
    800 : #0d0d0d,
    900 : #070707,
    A100 : #ff5050,
    A200 : #ff1d1d,
    A400 : #e90000,
    A700 : #cf0000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$accent-color:(
    50 : #fdede0,
    100 : #fad3b3,
    200 : #f7b680,
    300 : #f4984d,
    400 : #f18226,
    500 : #ef6c00,
    600 : #ed6400,
    700 : #eb5900,
    800 : #e84f00,
    900 : #e43d00,
    A100 : #ffffff,
    A200 : #ffe0d8,
    A400 : #ffb7a5,
    A700 : #ffa38b,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
