// ScrollBar
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow   : 0 0 5px @secondaryText;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background   : @themeColor;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: @secondaryThemeColor;
}

.sidebar {
    width           : 15%;
    flex-shrink     : 0;
    // overflow-x: hidden !important;
    background-color: @secondaryBg;
    z-index         : 100;
    // border-radius: 60px 0px 20px 0px;
        
    mat-accordion {
        .mat-expansion-panel {
            span {
                align-items: center;
            }

            .mat-expansion-panel-header {
                span {
                    color: @themeColor-Text;
                }

                &:hover {
                    color: @primaryText !important;
                }

                &:before {
                    border-color: @secondaryBg  !important;
                }

                &:after {
                    border-color: @secondaryBg  !important;
                    box-sizing: content-box;                        
                }
            }
        }
    }
}

.top-bar {
    width: calc(100vw - 15%)!important;
    left: 15%!important;
}