// ###################################
// ###################################
/* Basic Colors */
@basic-themeColor     : #ef6c00;
@secondary-themeColor: #e65100;
@basic-themeColor-Text: #fafafa;

/* Light Theme */
@primaryBg     : #f5f7f8;
@primaryText   : #171717;
@secondaryBg   : #ffffff;
@secondaryText : #212121;

/* Light Theme */
@primaryBg-Light     : #f5f7f8;
@primaryText-Light   : #171717;
@secondaryBg-Light   : #ffffff;
@secondaryText-Light : #212121;
/* Dark Theme */
@primaryBg-Dark     : #171717;
@primaryText-Dark   : #eeeeee;
@secondaryBg-Dark   : #424242;
@secondaryText-Dark : #6d6d6d; 

// ###################################
// ###################################
// Light Mode
:root[data-theme="theme-light"] {
    --primary-theme-color        : @basic-themeColor;
    --primary-text-color         : @primaryText-Light;
    --primary-bg-color           : @primaryBg-Light ;
    --secondary-theme-color      : @secondary-themeColor;
    --secondary-text-color       : @secondaryText-Light;
    --secondary-bg-color         : @secondaryBg-Light;
    --fade-primary-text-50       : fade(@primaryText-Light,50%);
    --fade-primary-text-15       : fade(@primaryText-Light,15%);
    --fade-secondary-text-50     : fade(@secondaryText-Light, 50%);
    --fade-theme-color-80        : fade(@basic-themeColor, 80%);
    --secondary-theme-color-15   : fade(@secondary-themeColor, 15%);
    --fade-primary-text-80       : fade(@primaryText-Light,80%);
    --fade-primary-text-25       : fade(@primaryText-Light,25%);
}
// Dark mode
:root[data-theme="theme-dark"] {
    --primary-theme-color        : @secondary-themeColor;
    --primary-text-color         : @primaryText-Dark;
    --primary-bg-color           : @primaryBg-Dark;
    --secondary-theme-color      : @basic-themeColor;
    --secondary-text-color       : @secondaryText-Dark;
    --secondary-bg-color         : @secondaryBg-Dark;
    --fade-primary-text-50       : fade(@primaryText-Dark,50%);
    --fade-primary-text-15       : fade(@primaryText-Dark,15%);
    --fade-secondary-text-50     : fade(@secondaryText-Dark, 50%);
    --fade-theme-color-80        : fade(@secondary-themeColor, 80%);
    --secondary-theme-color-15   : fade(@basic-themeColor, 15%);
    --fade-primary-text-80       : fade(@primaryText-Dark, 80%);
    --fade-primary-text-25       : fade(@primaryText-Dark, 25%);
}
/* === Set Colors === */
@themeColor        : var(--primary-theme-color);
@secondaryThemeColor    : var(--secondary-theme-color);
@secondaryThemeColor-15 : var(--secondary-theme-color-15);
@themeColor-Text   : var(--primary-text-color);
@themeColor-Text-50: var(--fade-primary-text-50);
@themeColor-Text-80: var(--fade-primary-text-80);
@themeColor-Text-25: var(--fade-primary-text-25);

@primaryBg     : var(--primary-bg-color);
@primaryText   : var(--primary-text-color);
@primaryText-15   : var(--fade-primary-text-15);
@secondaryBg   : var(--secondary-bg-color);
@secondaryText : var(--secondary-text-color);

// #####################################
// ##      Class Colors  & fades      ##
// #####################################

.bg-theme {
    background: var(--primary-theme-color);
}

.clr-theme {
    color: var(--primary-theme-color);
}

.clr-theme-text {
    color: var(--primary-text-color) !important;
}

.theme-tooltip {
    background: var(--primary-theme-color) !important;
}

.bg-pri {
    background: var(--primary-bg-color);
}

.clr-pri-text {
    color: var(--primary-text-color);
}

.clr-pri-text-f {
    color: var(--fade-primary-text-50);
}

.bg-sec {
    background: var(--secondary-bg-color);
}

.clr-sec-text {
    color: var(--secondary-text-color);
}

.clr-sec-text-f {
    color: var(--fade-secondary-text);
}

.bg-trans {
    background: transparent;
}